import React from 'react'
import PropTypes from 'prop-types'
import isFunction from 'lodash/isFunction'
import {
  AgBox,
  AgSearchInput,
  AgRow,
  AgButton,
  useDebounce,
} from '@aghealth/ag-components'
import {CheckboxList, ScrollY} from '.'

const styles = {
  SearchInput: {
    Root: {
      borderRadius: '4px',
    },
    Input: {
      py: '7.5px',
    },
    Icon: {
      size: '17.49px',
    },
  },
}

const SearchDropdown = ({onSearch, onSelect, onApply, selections, delay}) => {
  const [_delay, setDelay] = React.useState(0)
  const [search, setSearch] = React.useState('')
  const [_selections, setSelections] = React.useState(selections)
  const handleOnSearch = React.useCallback(
    value => {
      setSearch(value)
      setDelay(delay)
    },
    [setSearch, delay],
  )
  const handleOnSelect = React.useCallback(
    selection => {
      setSelections(prev =>
        prev.map(p => ({
          ...p,
          selected: p.id === selection.id ? !p.selected : p.selected,
        })),
      )
      if (isFunction(onSelect)) {
        onSelect(selection)
      }
    },
    [onSelect],
  )
  React.useEffect(() => {
    setSelections(selections)
  }, [selections])
  const debouncedSearch = useDebounce(search, _delay)
  React.useEffect(() => {
    onSearch(debouncedSearch)
  }, [debouncedSearch, onSearch])
  return (
    <AgBox>
      <AgSearchInput
        onChange={handleOnSearch}
        value={search}
        styles={styles.SearchInput}
        context={document}
      />
      {_selections?.length > 0 && (
        <ScrollY maxHeight={200} mt={2} width="100%">
          <CheckboxList list={_selections} onChange={handleOnSelect} />
        </ScrollY>
      )}
      {isFunction(onApply) && (
        <AgRow justifyContent="flex-end" mt={16}>
          <AgButton
            sx={{
              height: '44px',
              border: 'base',
              borderColor: 'success.500',
              borderRadius: 'base',
              bg: 'success.500',
              padding: '8px 18px',
              color: 'base.white',
              fontSize: 'md',
              fontWeight: 'semibold',
              lineHeight: 'md',
            }}
            onClick={onApply}
          >
            Apply
          </AgButton>
        </AgRow>
      )}
    </AgBox>
  )
}

SearchDropdown.propTypes = {
  onSelect: PropTypes.func,
  onSearch: PropTypes.func,
  onApply: PropTypes.func,
  selections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      selected: PropTypes.bool,
    }),
  ),
  delay: PropTypes.number,
}

SearchDropdown.defaultProps = {
  delay: 0,
}

export default React.memo(SearchDropdown)
